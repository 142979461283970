import { Route, Switch } from "react-router-dom";
import SNContentWrap from "../../components/SN/SNLayout/SNContentWrap";
import path from "../../screens/Root/routePaths";
import AllAllocatedNodes from "./AllAllocatedNodes";
import AllOrderNodes from "./AllOrderNodes";
import AllProductionNodes from "./AllProductionNodes";
import NodeAllocateForm from "./NodeAllocateForm";
import NodeProductionForm from "./NodeProductionForm";

const {
  all_nodes,
  allocate_node,
  allocate_node_list,
  all_production_nodes,
  create_production_nodes,
  create_allocate_node,
  edit_production_nodes,
} = path;

function NodesIndex() {
  return (
    <SNContentWrap>
      <Switch>
        <Route exact path={all_nodes} component={AllOrderNodes} />
        <Route exact path={allocate_node_list} component={AllAllocatedNodes} />
        <Route exact path={create_allocate_node} component={NodeAllocateForm} />
        <Route exact path={allocate_node} component={AllAllocatedNodes} />
        <Route
          exact
          path={all_production_nodes}
          component={AllProductionNodes}
        />
        <Route
          exact
          path={[create_production_nodes, edit_production_nodes]}
          component={NodeProductionForm}
        />
      </Switch>
    </SNContentWrap>
  );
}

export default NodesIndex;
