import { IconName } from "@fortawesome/fontawesome-svg-core";
import { Card, Popover } from "antd";
import { useState } from "react";
import SNAvatar from "../SNAvatar";
import Icon from "../SNIcon";
import "./index.scss";
const { Meta } = Card;

export interface UserData {
  first_name: string;
  last_name?: string;
  image?: string;
  id?: number;
}
export interface ContactData {
  email?: string;
  phone1?: string | number;
  phone2?: string | number;
}

export interface CompanyUserData {
  id?: any;
  company?: {
    company_email?: string;
    company_id?: string;
    company_name?: string;
    company_phone?: string;
    id?: number;
  };
  user: UserData;
  contact?: ContactData;
}

interface UserAvatarProps {
  size?: any;
  companyUser?: CompanyUserData;
  userId?: number;
  trigger?: "hover" | "click";
  alt?: any;
}

const DetailItem = ({ icon, value }: { icon: IconName; value?: string }) => {
  if (value)
    return (
      <p>
        <Icon icon={["fas", icon]} style={{ marginRight: 8, width: 14 }} />{" "}
        {value}
      </p>
    );
  return null;
};

const Content = ({ id, data }: { id?: number; data?: CompanyUserData }) => {
  if (data) {
    const {
      user: { image, first_name, last_name },
      contact,
    } = data;

    const details = [
      {
        icon: "briefcase",
        value: data?.company?.company_name || null,
      },
      {
        icon: "mobile",
        value: contact?.phone1 || "",
      },
      {
        icon: "phone-office",
        value: contact?.phone2 || "",
      },
      {
        icon: "envelope",
        value: contact?.email || "",
      },
    ];

    const name = `${first_name || ""} ${last_name || ""}`;

    return (
      <Meta
        className="user-meta-data"
        avatar={<SNAvatar src={image} name={name} size={80} />}
        title={name}
        description={
          <div className="details-icon-item">
            {details.map((item: any, key) =>
              item.value ? (
                <DetailItem icon={item.icon} value={item.value} key={key} />
              ) : null
            )}
          </div>
        }
      />
    );
  }
  return null;
};
function UserAvatar(props: UserAvatarProps) {
  const { companyUser, size, trigger = "hover", userId, alt } = props;
  const [isVisible, setIsVisible] = useState<boolean>(false);
  if (!companyUser) return null;
  const name = `${companyUser.user.first_name || ""} ${
    companyUser.user.last_name || ""
  }`;
  return (
    <Popover
      content={isVisible && <Content id={userId} data={companyUser} />}
      trigger={trigger}
      overlayClassName="sn-user-details-popover"
      open={isVisible}
      onOpenChange={setIsVisible}
      destroyTooltipOnHide
    >
      <SNAvatar
        src={companyUser.user.image}
        name={name}
        size={size}
        alt={alt}
        style={{ cursor: "pointer" }}
      />
    </Popover>
  );
}

export default UserAvatar;
