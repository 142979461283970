import { Col, Form, Row, Space } from "antd";
import BraftEditor from "braft-editor";
import SNButton from "components/SN/SNButton";
import SNFormLoading from "components/SN/SNFormLoading";
import SNPanel from "components/SN/SNPanel";
import { DATE_TIME_FORM_VALUE } from "constants/date";
import { successAlert } from "lib/alertHelper";
import dayjs from "dayjs";
import { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import path from "screens/Root/routePaths";
import NewsFeedFormSection from "../components/NewsFeedFormSection";
import newsfeedService from "../service";

const NewsFeedForm = (props) => {
  const { history } = props;
  const [form] = Form.useForm();
  const [isFormChanged, setIsFormChanged] = useState(false);

  const match = useRouteMatch<any>(path.edit_new_feed);
  const news_feed_id = match?.params?.id;

  let initValues: any = {
    publish_at: dayjs(),
    target: 0,
    story: BraftEditor.createEditorState(""),
    sticky_days: 0,
  };

  const [editData] = newsfeedService.useNewFeedDetails(news_feed_id);
  if (news_feed_id && !editData) return <SNFormLoading />;

  if (editData) {
    const { story, publish_at } = editData;

    initValues = {
      ...editData,
      publish_at: dayjs(publish_at),
      story: BraftEditor.createEditorState(story),
    };
  }

  const _handleSubmitForm = (allFields: any) => {
    const { publish_at, story, story_sv } = allFields;

    const formData = {
      ...allFields,
      publish_at: publish_at?.format(DATE_TIME_FORM_VALUE),
      story: `${story?.toHTML()}`,
      story_sv: `${story_sv?.toHTML()}`,
    };

    if (news_feed_id) {
      newsfeedService.editNew(news_feed_id, formData, (data) => {
        setIsFormChanged(false);
        history.goBack();
        successAlert(
          <div>
            News feed{" "}
            <a href={`${path.new_feed_details.replace(":id", data.id)}`}>
              {data.title}
            </a>{" "}
            has been edited
          </div>
        );
      });
    } else {
      newsfeedService.createNew(formData, (data) => {
        setIsFormChanged(false);
        history.push(path.new_feed);
        successAlert(
          <div>
            News feed{" "}
            <a href={`${path.new_feed_details.replace(":id", data.id)}`}>
              {data.title}
            </a>{" "}
            has been created
          </div>
        );
      });
    }
  };

  const _handleFormChange = () => {
    if (!isFormChanged) setIsFormChanged(true);
  };

  return (
    <SNPanel panelTitle="Create new feed" style={{ marginBottom: 15 }}>
      <Form
        layout="vertical"
        form={form}
        onFinish={_handleSubmitForm}
        onValuesChange={_handleFormChange}
        initialValues={initValues}
        className="sn-form"
      >
        <NewsFeedFormSection form={form} />

        <Row style={{ marginTop: 20 }}>
          <Col span={24} style={{ textAlign: "right" }}>
            <Space size={8}>
              <SNButton
                title="Cancel"
                className="sn-btn-big"
                onClick={() => history.goBack()}
              />
              <SNButton
                title="Save"
                type="primary"
                className="sn-btn-big"
                onClick={form.submit}
              />
            </Space>
          </Col>
        </Row>
      </Form>
    </SNPanel>
  );
};

export default NewsFeedForm;
