import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import ReactDOM from "react-dom";
import { ENV_NAME } from "settings/env";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./settings/serviceWorker";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  environment: ENV_NAME,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});



ReactDOM.render(<App />, document.getElementById("root"));
serviceWorker.unregister();
