import fileDownload from "js-file-download";
import dayjs from "dayjs";
import xlsx from "xlsx";
import { successAlert } from "../../../../lib/alertHelper";

const xlsxUtils = xlsx.utils;

const toCSV = (
  json: any,
  fileName: string = `QR-Tag-${dayjs().format("YYYY-MM-DD")}`
) => {
  const ws = xlsxUtils.json_to_sheet(json);
  const csv_file = xlsxUtils.sheet_to_csv(ws);
  /* generate file and send to client */

  successAlert("The file is ready to download");
  fileDownload(csv_file, `${fileName}.csv`);
};

const toXLS = (
  json: any,
  fileName: string = `QR-Tag-${dayjs().format("YYYY-MM-DD")}`
) => {
  const ws = xlsxUtils.json_to_sheet(json);

  const wb = xlsxUtils.book_new();
  xlsxUtils.book_append_sheet(wb, ws, fileName);
  /* generate file and send to client */

  successAlert("The file is ready to download");
  xlsx.writeFile(wb, `${fileName.trim()}.xls`);
};

export const exportNodeService = { toCSV, toXLS };
