import { Col, Form, Row, Space } from "antd";
import { useState } from "react";
import { Prompt } from "react-router-dom";
import SNButton from "../../../../components/SN/SNButton";
import SNPanel from "../../../../components/SN/SNPanel";
import InvoiceTemplateForm from "./InvoiceTemplateForm";
import { initInvoiceItems } from "./InvoiceTemplateForm/InvoiceItemSectionForm";
import invoiceService from "../service";
import SNFormLoading from "components/SN/SNFormLoading";
// import { planBillingPeriod } from "screens/Subscriptions/Plans/data.t";
import path from "screens/Root/routePaths";
import { successAlert } from "lib/alertHelper";
import dayjs from "dayjs";

const InvoiceForm = (props) => {
  const { history, match } = props;
  const [form] = Form.useForm();

  const { invoice_id } = match.params;

  const [isFormChanged, setIsFormChanged] = useState(false);

  let initialValues: any = {
    invoice_terms: 15,
    invoice_items: [initInvoiceItems],
    total_vat: 0,
    total_price: 0,
    date: dayjs(),
    due_date: dayjs(),
  };

  const [editData] = invoiceService.useInvoiceDetails(invoice_id);
  if (invoice_id && !editData) return <SNFormLoading />;

  if (editData) {
    initialValues = {
      ...editData,
      date: dayjs(editData.date),
      due_date: dayjs(editData.due_date),
      subscription: editData.subscription.id,
    };
    document.title = `Edit invoice #${invoice_id}`;
  } else {
    document.title = "Create new invoice";
  }

  const onFinish = (values) => {
    const formData = {
      ...values,
      date: values.date.format("YYYY-MM-DD"),
      due_date: values.due_date.format("YYYY-MM-DD"),
    };
    if (invoice_id) {
      invoiceService.editInvoice(invoice_id, formData, () => {
        setIsFormChanged(false);
        history.goBack();
        successAlert(`Invoice #${invoice_id} has been edited`);
      });
    } else {
      invoiceService.addInvoice(formData, () => {
        setIsFormChanged(false);
        history.push(path.all_invoices);
        successAlert("Invoice has been created");
      });
    }
  };

  const handleOnChange = (changedField, allFields) => {
    if (!isFormChanged) setIsFormChanged(true);
    const { invoice_items } = allFields;

    const newItems = invoice_items
      ? invoice_items.map((item) => {
          const { discount, price, quantity } = item;

          if (price && quantity) {
            const entityPrice =
              parseInt(quantity) * parseFloat(price) -
              (discount && parseFloat(discount) >= 0
                ? parseFloat(discount)
                : 0);

            return {
              ...item,
              total_entity: entityPrice,
            };
          }

          return {
            ...item,
            total_entity: 0,
          };
        })
      : [];
    if (changedField.subscription) {
      const { subscription } = changedField;
      const invoiceSubcriptionItems = {
        category: 1,
        name: subscription.name,
        quantity: 1,
        unit: "pcs",
        price: subscription.price,
        vat: 12,
        discount: 0,
      }
      newItems.push(invoiceSubcriptionItems);
    }
    form.setFieldsValue({ invoice_items: newItems });  
  };

  return (
    <SNPanel panelTitle={invoice_id ? "Edit invoice" : "Create new invoice"}>
      <Prompt
        when={isFormChanged}
        message={
          "You may have unsaved data on this form. Leaving this form will discard all the changes!"
        }
      />
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={initialValues}
        className="sn-form"
        onValuesChange={handleOnChange}
      >
        <InvoiceTemplateForm form={form}/>

        <Row>
          <Col span={24} style={{ textAlign: "right" }}>
            <Space size={8}>
              <SNButton
                title="Cancel"
                className="sn-btn-big"
                onClick={() => {
                  history.goBack();
                }}
              />
              <SNButton
                title="Save"
                type="primary"
                className="sn-btn-big"
                onClick={form.submit}
              />
            </Space>
          </Col>
        </Row>
      </Form>
    </SNPanel>
  );
};

export default InvoiceForm;
