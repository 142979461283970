import { Settings as ProSettings } from "@ant-design/pro-layout";
import { FC } from "react";
import Avatar from "./AvatarDropdown";
import "./index.scss";
export interface GlobalHeaderRightProps extends Partial<ProSettings> {}

const GlobalHeaderRight: FC<GlobalHeaderRightProps> = (props) => {
  return (
    <div style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: 16,
      paddingBlock: 16,
    }}>
      <Avatar {...props} />

    </div>
  );
};

export default GlobalHeaderRight;
