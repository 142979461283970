import { blue } from "@ant-design/colors";
import ProTable, {
  ActionType,
  ProColumns,
  RequestData,
} from "@ant-design/pro-table";
import { BackTop } from "antd";
import qs from "query-string";
import { useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import api, { url } from "../../../api";
import SNButton from "../../../components/SN/SNButton";
import SNButtonGroup from "../../../components/SN/SNButtonGroup";
import { SNMenuItemProps } from "../../../components/SN/SNMenuItem";
import SNPanel from "../../../components/SN/SNPanel";
import { formatDate, generateEndNodeId } from "../../../lib";
import { paramsToObject } from "../../../lib/locationSearch";
import path from "../../Root/routePaths";
import NodeAlllocateFilter from "../components/NodeAlllocateFilter";
import { NodeAllocationApi } from "../data.t";

const AllAllocatedNodes = (props) => {
  const location = useLocation();
  const history = useHistory();
  const listRef = useRef<ActionType>(null);

  const _renderList = () => {
    const columns: ProColumns<NodeAllocationApi>[] = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        sorter: true,
      },
      {
        title: "Allocated to",
        dataIndex: "company",
        key: "company",
        render: (value: NodeAllocationApi["company"]) => value.company_name,
      },
      {
        title: "Node range",
        key: "node_tange",
        render: ({ initial, number, series, total }: NodeAllocationApi) => (
          <span>
            {`${initial}${number}${series}`} ⮕{" "}
            {generateEndNodeId(initial, number, total, series)}
          </span>
        ),
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
      },
      {
        title: "Allocated on",
        dataIndex: "created_at",
        sorter: true,
        key: "created_at",
        render: (value) => formatDate(value, "YYYY-MM-DD, HH:mm"),
      },
      {
        title: "Ordered at",
        dataIndex: "ordered_at",
        sorter: true,
        key: "ordered_at",
        valueType: "date",
      },
      {
        title: "",
        key: "more",
        render: (value: any) => {
          const actions: SNMenuItemProps[] = [
            {
              key: "info",
              icon: ["fal", "info"],
              title: "Details",
            },
          ];
          return <SNButtonGroup dropdown={actions} />;
        },
      },
    ];

    return (
        <ProTable<NodeAllocationApi, {}>
          actionRef={listRef}
          request={async (
            { pageSize, current },
            sort
          ): Promise<RequestData<NodeAllocationApi>> => {
            const sortKey = Object.keys(sort).length
              ? Object.keys(sort)[0]
              : "";
            const sortParams = sortKey.length
              ? `&ordering=${sort[sortKey] === "descend" ? "-" : ""}${sortKey}`
              : "";
            const locationParams = qs.stringify(
              paramsToObject(location.search)
            );

            const urlRequest = `${
              url.NODE_ALLOCATION
            }?limit=${pageSize}&offset=${
              (current - 1) * pageSize
            }${sortParams}${locationParams ? `&${locationParams}` : ""}`;

            const res = await api.get(urlRequest);

            return {
              data: res.data.results,
              success: true,
              total: res.data.count,
            };
          }}
          params={location.search}
          headerTitle="Allocated Nodes"
          search={false}
          options={{
            setting: false,
            fullScreen: false,
            density: false,
          }}
          toolBarRender={() => [
            <SNButton
              key="plus-button"
              title="Create allocate node"
              iconcolor={blue.primary}
              icon="plus"
              onClick={() => history.push(path.create_allocate_node)}
            />,
            <NodeAlllocateFilter key="filter" />,
          ]}
          size={"small"}
          pagination={{ showQuickJumper: true, pageSize: 100 }}
          rowKey={(key) => key.id}
          columns={columns}
          dateFormatter="string"
        />
    );
  };

  return (
    <SNPanel view="list">
      {_renderList()}
      <BackTop>
        <SNButton
          shape="circle"
          icon={"arrow-to-top"}
          className="sn-button-scroll-top"
          size="middle"
        />
      </BackTop>
    </SNPanel>
  );
};

export default AllAllocatedNodes;
