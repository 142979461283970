import Axios from "axios";
import { useEffect, useState } from "react";
import api, { url } from "../../../api";
import { PaymentApi } from "./data.t";

const usePaymentDetails = (id: any): [PaymentApi, Function] => {
  const [subData, setSubData] = useState<PaymentApi>(null);
  const [willReload, setWillReload] = useState<boolean>(false);

  let source = Axios.CancelToken.source();
  const reload = () => {
    setSubData(null);
    setWillReload(!willReload);
  };

  const fetchDetails = () => {
    api.get(`${url.SUBSCRIPTION_INVOICE_PAYMENT}${id}/`).then((res) => {
      setSubData(res.data);
    });
  };

  useEffect(() => {
    if (id) {
      fetchDetails();
    } else {
      setSubData(null);
    }
    return () => {
      source.cancel("Cancelling in cleanup");
    };
  }, [id, willReload]);

  return [subData, reload];
};

function createPayment(params: any = {}, postActions?: (data: any) => void) {
  api.post(url.SUBSCRIPTION_INVOICE_PAYMENT, params).then((response) => {
    if (response.status === 201) {
      if (postActions) postActions(response.data);
    }
  });
}

function editPayment(
  id: any,
  params: any = {},
  postActions?: (data: any) => void
) {
  api
    .put(`${url.SUBSCRIPTION_INVOICE_PAYMENT}${id}/`, params)
    .then((response) => {
      if (response.status === 200) {
        if (postActions) postActions(response.data);
      }
    });
}

const paymentService = {
  usePaymentDetails,
  createPayment,
  editPayment,
};

export default paymentService;
