import jwtDecode from "jwt-decode";
import dayjs from 'dayjs'

export function is_authenticated(user: any, auth: any) {
  if (auth && user) {
    const { access_token } = auth;
    const decodeToken = jwtDecode<any>(access_token);

    return (
      auth.hasOwnProperty("access_token") &&
      user.hasOwnProperty("is_superuser") &&
      user.is_superuser &&
      decodeToken && dayjs().unix() < decodeToken.exp
    );
  }
}
