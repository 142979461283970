import { Col, Form, Row, Space } from "antd";
import dayjs from "dayjs";

import SNButton from "../../../components/SN/SNButton";
import SNPanel from "../../../components/SN/SNPanel";
import { successAlert } from "../../../lib/alertHelper";
import nodeService from "../service";
import ProductionForm from "./components/NodeProductionTemplateForm";

const NodeProductionForm = ({ onFinishForm, onCancelForm }) => {
  const [form] = Form.useForm();

  const initValues = {
    type: 1,
    // initial: "SE",
    // series: "AA",
    // number: "0000",
    // total: 10,
    production_date: dayjs(),
  };

  const onFinish = (values) => {
    const { description } = values;

    const formData = {
      ...values,
      type: 1,
      production_date: dayjs().format("YYYY-MM-DD"),
      description: description || "",
    };

    nodeService.addProduction(formData, (data) => {
      successAlert("Production nodes has been created");
      onFinishForm && onFinishForm(data);
    });
  };

  return (
    <SNPanel panelTitle="Add production nodes" style={{ marginBottom: 15 }}>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={initValues}
        className="sn-form"
      >
        <ProductionForm form={form} />

        <Row>
          <Col span={24} style={{ textAlign: "right" }}>
            <Space size={8}>
              <SNButton
                title="Cancel"
                className="sn-btn-big"
                onClick={onCancelForm}
              />
              <SNButton
                title="Save"
                type="primary"
                className="sn-btn-big"
                onClick={form.submit}
              />
            </Space>
          </Col>
        </Row>
      </Form>
    </SNPanel>
  );
};

export default NodeProductionForm;
