import { List, Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { useRef } from "react";
import { url } from "../../../../api";
import SNApiSelect, {
  SNApiSelectRef,
} from "../../../../components/SN/SNForm/SNApiSelect";
import SNFormItem from "../../../../components/SN/SNForm/SNFormItem";
import { planBillingPeriod } from "../../../Subscriptions/Plans/data.t";
import { SubscriptionApi } from "../../../Subscriptions/data.t";

const { Option } = Select;
interface SNSubscriptionsSelectProps extends SelectProps<any> {
  placeholdertext?: string;
  required?: boolean;
  name: string;
  label?: any;
  formClassName?: string;
  fields?: string;
}
function SNSubscriptionsSelect(props: SNSubscriptionsSelectProps) {
  const {
    placeholdertext,
    fields,
    required,
    name,
    label,
    formClassName,
    onSelect,
    ...newProps
  } = props;
  const apiSelectRef = useRef<SNApiSelectRef>();

  const optionFormat = (item: SubscriptionApi) => {
    const { id, company, price, plan, billing_period } = item;
    const title = `#${id} - ${company.company_name} - ${plan.name}`;
    const description =
      billing_period === 0
        ? "Free plan"
        : `${price} (SEK) - ${planBillingPeriod[billing_period]}`;

    return (
      <Option value={id} key={id} label={title} data={item}>
        <List.Item.Meta
          className="option-item-has-image"
          title={title}
          description={description}
        />
      </Option>
    );
  };

  return (
    <>
      <SNFormItem
        name={name}
        label={label}
        required={required}
        className={formClassName}
      >
        <SNApiSelect
          {...newProps}
          placeholdertext={placeholdertext}
          urlObject={`${url.SUBSCRIPTION}`}
          optionFormat={optionFormat}
          limit={100}
          handleChange={onSelect}
          ref={apiSelectRef}
        />
      </SNFormItem>
    </>
  );
}

export default SNSubscriptionsSelect;
