import ProTable, {
  ActionType,
  ProColumns,
  RequestData,
} from "@ant-design/pro-table";
import { Col } from "antd";
import { useContext, useRef } from "react";
import api, { url } from "../../../../api";
import { SubscriptionApi } from "../../../Subscriptions/data.t";
import { getInvoiceStatusTag } from "../../../Subscriptions/Invoices/const";
import CompanyDetailsContext from "../context";

const InvoiceTab = () => {
  const { companyData } = useContext(CompanyDetailsContext);
  const listRef = useRef<ActionType>();

  const _renderInvoiceList = () => {
    const columns: ProColumns<SubscriptionApi>[] = [
      {
        dataIndex: "number",
        key: "number",
        title: "Number",
        sorter: true,
      },
      {
        dataIndex: "company",
        key: "company",
        title: "Company",
        render: (value: any) => value.company_name,
      },
      {
        dataIndex: "date",
        key: "date",
        title: "Invoice date",
        sorter: true,
        valueType: "date",
      },
      {
        dataIndex: "due_date",
        key: "due_date",
        title: "Due date",
        sorter: true,
        valueType: "date",
      },
      {
        dataIndex: "total",
        key: "total",
        title: "Invoice amount",
        render: (value) => `${value ?? 0} (SEK)`,
      },
      {
        dataIndex: "amount_paid",
        key: "amount_paid",
        title: "Amount paid",
        render: (value: any) => `${value ?? 0} (SEK)`,
      },
      {
        dataIndex: "status",
        key: "status",
        title: "Status",
        render: (value: any) => getInvoiceStatusTag(value),
      },
    ];

    return (
      <Col xl={24} lg={24} md={24} xs={24} className="sn-card-content-body top">
          <ProTable<SubscriptionApi, { search: string }>
            actionRef={listRef}
            request={async (
              { pageSize, current, keyword },
              sort,
              filter
            ): Promise<RequestData<SubscriptionApi>> => {
              const sortKey = Object.keys(sort).length
                ? Object.keys(sort)[0]
                : "";
              const sortParams = sortKey.length
                ? `&ordering=${
                    sort[sortKey] === "descend" ? "-" : ""
                  }${sortKey}`
                : "";

              const urlRequest = `${
                url.SUBSCRIPTION_INVOICE
              }?limit=${pageSize}&offset=${
                (current - 1) * pageSize
              }${sortParams}&company=${companyData.id}`;
              const res = await api.get(urlRequest);

              return {
                data: res.data.results,
                success: true,
                total: res.data.count,
              };
            }}
            search={false}
            toolBarRender={false}
            options={false}
            size={"small"}
            pagination={{ pageSize: 100, showQuickJumper: true }}
            rowKey={(key) => key.id}
            columns={columns}
            dateFormatter="string"
          />
      </Col>
    );
  };

  return <>{_renderInvoiceList()}</>;
};

export default InvoiceTab;
