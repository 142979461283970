import { List, Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { useRef } from "react";
import { url } from "../../../../api";
import SNApiSelect, {
  SNApiSelectRef,
} from "../../../../components/SN/SNForm/SNApiSelect";
import SNFormItem from "../../../../components/SN/SNForm/SNFormItem";
import {
  Plan,
  planBillingPeriod,
} from "../../../Subscriptions/Plans/data.t";

const { Option } = Select;
interface SNSubPlansSelectProps extends SelectProps<any> {
  placeholdertext?: string;
  required?: boolean;
  name: string;
  label?: any;
  formClassName?: string;
  fields?: string;
}
function SNSubPlansSelect(props: SNSubPlansSelectProps) {
  const {
    placeholdertext,
    fields,
    required,
    name,
    label,
    formClassName,
    onSelect,
    placeholder = "Select company from the list",
    ...newProps
  } = props;
  const apiSelectRef = useRef<SNApiSelectRef>();

  const optionFormat = (item: Plan) => {
    const { id, name, price, billing_period } = item;
    const description =
      billing_period === 0
        ? "Free plan"
        : `${price} (SEK) ${
            planBillingPeriod[billing_period]
              ? `/ ${planBillingPeriod[billing_period]}`
              : ""
          }`;

    return (
      <Option value={id} key={id} label={name} data={item}>
        <List.Item.Meta
          className="option-item-has-image"
          title={name}
          description={description}
        />
      </Option>
    );
  };

  return (
    <>
      <SNFormItem
        name={name}
        label={label}
        required={required}
        className={formClassName}
      >
        <SNApiSelect
          {...newProps}
          placeholdertext={placeholdertext}
          urlObject={`${url.SUBSCRIPTION_PLAN}?status=1`}
          optionFormat={optionFormat}
          limit={100}
          handleChange={onSelect}
          ref={apiSelectRef}
        />
      </SNFormItem>
    </>
  );
}

export default SNSubPlansSelect;
