import { Col, Form, Row, Space } from "antd";
import { useState } from "react";
import { Prompt } from "react-router-dom";
import SNButton from "components/SN/SNButton";
import SNPanel from "components/SN/SNPanel";
import SNFormLoading from "components/SN/SNFormLoading";
import { planBillingPeriod } from "screens/Subscriptions/Plans/data.t";
import { successAlert } from "lib/alertHelper";
import path from "screens/Root/routePaths";

import PaymentTemplateForm from "./components/PaymentTemplateForm";
import paymentService from "../service";


const PaymentForm = (props) => {
  const { history, match } = props;
  const [form] = Form.useForm();

  const { pay_id } = match.params;

  const [isFormChanged, setIsFormChanged] = useState(false);

  let initialValues: any = {
    pay_amount: 0,
    pay_method: 1,
  };

  const [editData] = paymentService.usePaymentDetails(pay_id);
  if (pay_id && !editData) return <SNFormLoading />;

  if (editData) {
    initialValues = {
      ...editData,
      billing_period: planBillingPeriod.hasOwnProperty(editData.billing_period)
        ? editData.billing_period
        : 0,
      setting_price: editData.plan_settings.length
        ? editData.plan_settings
            .map((x) => x.price ?? "0")
            .reduce((a, b) => (parseFloat(a) + parseFloat(b)).toFixed(2))
        : "0",
    };
  }

  const onFinish = (values) => {
    const formData = {
      ...values,
    };
    if (pay_id) {
      paymentService.editPayment(pay_id, formData, () => {
        setIsFormChanged(false);
        history.goBack();
        successAlert("Subcription plan has been edited");
      });
    } else {
      paymentService.createPayment(formData, () => {
        setIsFormChanged(false);
        history.push(path.all_sub_plans);
        successAlert("Subcription plan has been created");
      });
    }
  };

  const handleOnChange = (changedField, allFields) => {
    if (!isFormChanged) setIsFormChanged(true);
  };

  return (
    <SNPanel panelTitle={pay_id ? "Edit payment" : "Create new payment"}>
      <Prompt
        when={isFormChanged}
        message={
          "You may have unsaved data on this form. Leaving this form will discard all the changes!"
        }
      />
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={initialValues}
        className="sn-form"
        onValuesChange={handleOnChange}
      >
        <PaymentTemplateForm form={form} />

        <Row>
          <Col span={24} style={{ textAlign: "right" }}>
            <Space size={8}>
              <SNButton
                title="Cancel"
                className="sn-btn-big"
                onClick={() => {
                  history.goBack();
                }}
              />
              <SNButton
                title="Save"
                type="primary"
                className="sn-btn-big"
                onClick={form.submit}
              />
            </Space>
          </Col>
        </Row>
      </Form>
    </SNPanel>
  );
};

export default PaymentForm;
