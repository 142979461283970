import { Col, Row } from "antd";
import dayjs from "dayjs";
import { useContext } from "react";
import SNDescription from "../../../../components/SN/SNDescription";
import { SNDescriptionItemProps } from "../../../../components/SN/SNDescription/data.t";
import SNText from "../../../../components/SN/SNText";
import { addressManager, formatName } from "../../../../lib";
import { getCompanyStatusTag, getOwnerStatusTag } from "../../const";
import { compIndustry } from "../../data.t";
import CompanyDetailsContext from "../context";

const OverviewTab = () => {
  const { companyData, companyStats } = useContext(CompanyDetailsContext);

  const {
    owner,
    allow_partnership_request,
    website,
    is_active,
    industry_type,
    company_email,
    company_phone,
    address,
    created_at,
    updated_at,
    vat_reg_number,
    invoice_address,
    invoice_email,
    invoice_ref,
  } = companyData;

  const _renderBasicInfo = () => {
    const basicColumns: SNDescriptionItemProps[] = [
      {
        label: "Account",
        value: formatName(owner.first_name, owner.last_name),
      },
      {
        label: "Name",
        value: formatName(owner.first_name, owner.last_name),
      },
      {
        label: "Registered at",
        value: dayjs(created_at).format("YYYY-MM-DD HH:mm"),
      },
      {
        label: "Status",
        value: getOwnerStatusTag(owner.is_active),
      },
    ];

    return (
      <Col xl={24} lg={24} md={24} xs={24} className="sn-card-content-body top">
        <SNDescription
          icon={"address-card"}
          title={"Owner Infomation"}
          bordered
          column={2}
          rowSize={["20%", "30%"]}
          size={"small"}
          itemData={basicColumns}
        />
      </Col>
    );
  };

  const _renderCompanyInfo = () => {
    const metaColumns = [
      {
        label: "Status",
        value: getCompanyStatusTag(is_active),
      },
      {
        label: "Org number",
        value: "",
      },
      {
        label: "Industry type",
        value: compIndustry[industry_type],
      },
      {
        label: "Access partnership",
        value: (
          <SNText strong text={allow_partnership_request ? "YES" : "NO"} />
        ),
      },
      {
        label: "Phone",
        value: company_phone,
      },
      {
        label: "Email",
        value: company_email,
      },
      {
        label: "Website",
        value: website,
      },
      {
        label: "Address",
        value: addressManager(address),
      },
      {
        label: "Created at",
        value: dayjs(created_at).format("YYYY-MM-DD HH:mm"),
      },
      {
        label: "Updated at",
        value: updated_at ? dayjs(updated_at).format("YYYY-MM-DD HH:mm") : "",
      },
    ];
    return (
      <Col xl={24} lg={24} md={24} xs={24} className="sn-card-content-body">
        <SNDescription
          icon={"building"}
          title={"Company Infomation"}
          bordered
          column={2}
          size={"small"}
          rowSize={["20%", "30%"]}
          itemData={metaColumns}
        />
      </Col>
    );
  };

  const _renderInvoiceInfo = () => {
    const subColumns = [
      {
        label: "VAT reg",
        value: vat_reg_number,
      },
      {
        label: "Invoice Ref",
        value: invoice_ref,
      },
      {
        label: "Invoice email",
        value: invoice_email,
      },
      {
        label: "Invoice address",
        value: addressManager(invoice_address),
      },
    ];

    return (
      <Col xl={24} lg={24} md={24} xs={24} className="sn-card-content-body">
        <SNDescription
          icon={"file-invoice"}
          title={"Invoice Infomation"}
          bordered
          column={2}
          rowSize={["20%", "30%"]}
          size={"small"}
          itemData={subColumns}
        />
      </Col>
    );
  };

  const _renderSettingUsages = () => {
    const settingcolumns: SNDescriptionItemProps[] = [
      {
        label: "User",
        value: companyStats?.user || 0,
      },
      {
        label: "Node",
        value: companyStats?.node || 0,
      },
      {
        label: "Asset",
        value: companyStats?.asset || 0,
      },
      {
        label: "Place",
        value: companyStats?.place || 0,
      },
      {
        label: "Work order",
        value: companyStats?.work_order || 0,
      },
      {
        label: "Invoice",
        value: companyStats?.invoice || 0,
      },
      {
        label: "Forms",
        value: companyStats?.form || 0,
      },
      {
        label: "Parts",
        value: companyStats?.parts || 0,
      },
      {
        label: "Error reports",
        value: companyStats?.error || 0,
      },
      {
        label: "Services",
        value: companyStats?.service || 0,
      },
      {
        label: "Task",
        value: companyStats?.task || 0,
      },
      {
        label: "Service plan",
        value: companyStats?.serviceplan || 0,
      },
      {
        label: "Document storage",
        value: companyStats?.document_storage || 0,
      },
      {
        label: "Image storage",
        value: companyStats?.image_storage || 0,
      }
    ];

    return (
      <Col xl={24} lg={24} md={24} xs={24} className="sn-card-content-body top">
        <SNDescription
          icon={"users-cog"}
          title={"Usage"}
          bordered
          column={1}
          rowSize={["25%", "25%"]}
          size={"small"}
          itemData={settingcolumns}
        />
      </Col>
    );
  };

  return (
    <>
      <Row gutter={[12, 16]}>
        <Col xl={18} lg={24} md={24} xs={24}>
          {_renderBasicInfo()}
          {/* {_renderSubscriptionInfo()} */}
          {_renderCompanyInfo()}
          {_renderInvoiceInfo()}
        </Col>
        <Col xl={6} lg={8} md={8} xs={8}>
          {_renderSettingUsages()}
        </Col>
      </Row>
    </>
  );
};

export default OverviewTab;
