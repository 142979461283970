import { Col } from "antd";
import { Link } from "react-router-dom";
import SN404Page from "../../../components/SN/SN404Page";
import SNButtonGroup from "../../../components/SN/SNButtonGroup";
import SNDescription from "../../../components/SN/SNDescription";
import { SNDescriptionItemProps } from "../../../components/SN/SNDescription/data.t";
import SNDetailsLoading from "../../../components/SN/SNDetailsLoading";
import SNEmpty from "../../../components/SN/SNEmpty";
import { SNMenuItemProps } from "../../../components/SN/SNMenuItem";
import SNPanel from "../../../components/SN/SNPanel";
import SNTitle from "../../../components/SN/SNTitle";
import { addressManager } from "../../../lib";
import path from "../../Root/routePaths";
import { planBillingPeriod } from "../Plans/data.t";
import { getOnEndTag, getSubStatusTag } from "../const";
import subService from "../service";

const SubcriptionDetails = (props) => {
  const { match } = props;
  const { sub_id } = match.params;

  /**
   * Render view region
   */

  if (!sub_id) return <SN404Page />;

  const [subData, refreshData] = subService.useSubscriptionDetails(sub_id);

  if (!subData) return <SNDetailsLoading />;

  const {
    id,
    status,
    billing_period,
    price,
    company,
    plan,
    start_date,
    note,
    bill_to,
    on_end
  } = subData;

  const renderBasicInfo = () => {
    const basicColumns: SNDescriptionItemProps[] = [
      {
        label: "Company",
        value: (
          <Link
            to={{
              pathname: path.tenant_details.replace(":id", `${company.id}`),
            }}
          >
            {company.company_name}
          </Link>
        ),
      },
      {
        label: "Status",
        value: <span>{getSubStatusTag(status)}</span>,
      },
      {
        label: "Plan",
        value: (
          <Link
            to={{
              pathname: path.sub_plan_details.replace(":plan_id", `${plan.id}`),
            }}
          >
            {plan.name}
          </Link>
        ),
      },
      {
        label: "Billing period",
        value: planBillingPeriod[billing_period],
      },
      {
        label: "Price",
        value: price ? `${price} (SEK)` : "",
      },
      {
        label: "Start_date",
        value: start_date,
        valueType: "date",
      },
      // {
      //   label: "Invoice terms",
      //   value: subInvoiceTerms[invoice_terms],
      // },
      {
        label: "On end",
        value: getOnEndTag(on_end),
      },
      {
        label: "Note",
        value: note,
      },
    ];

    return (
      <Col xl={24} lg={24} md={24} xs={24} style={{ marginTop: 20 }}>
        <SNDescription
          icon={["fal", "info"]}
          title={"General Infomation"}
          bordered
          column={2}
          rowSize={["15%", "35%"]}
          size={"small"}
          itemData={basicColumns}
        />
      </Col>
    );
  };

  const renderContact = () => {
    return (
      <Col xl={8} lg={12} md={12} xs={24} style={{ marginTop: 20 }}>
        <SNTitle
          title="Contact"
          icon="user-alt"
          className="sn-section-title top"
        />

        {!bill_to && (
          <SNEmpty
            icon="address-card"
            title={"No contact for this subscription"}
          />
        )}
        {bill_to && (
          <SNDescription
            title={null}
            bordered
            column={1}
            rowSize={["10%", "35%"]}
            size={"small"}
            itemData={[
              {
                label: "Email",
                value: bill_to.contact?.email,
              },
              {
                label: "Phone",
                value: bill_to.contact?.phone1,
              },
              {
                label: "Mobile",
                value: bill_to.contact?.phone2,
              },
              {
                label: "Address",
                value: addressManager(bill_to.address),
              },
            ]}
          />
        )}
      </Col>
    );
  };

  const renderRightOption = () => {
    const actions: SNMenuItemProps[] = [
      {
        key: "refresh",
        icon: ["fal", "redo"],
        title: "Refresh",
        onClick: () => refreshData(),
      },
      {
        key: "edit",
        icon: ["fal", "pencil-alt"],
        title: "Edit",
        link: path.edit_subscription.replace(":sub_id", sub_id),
      },
    ];
    return <SNButtonGroup dropdown={actions} />;
  };

  return (
    <>
      <SNPanel
        view={"details"}
        panelTitle={`Subscription #${id}`}
        extra={renderRightOption()}
        bodyStyle={{ paddingTop: 5 }}
      >
        {renderBasicInfo()}
        {renderContact()}
      </SNPanel>
    </>
  );
};

export default SubcriptionDetails;
