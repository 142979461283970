import { Col, Form, Row, Space } from "antd";
import { useState } from "react";
import dayjs from "dayjs";

import { Prompt } from "react-router-dom";
import SNButton from "components/SN/SNButton";
import SNPanel from "components/SN/SNPanel";
import SNFormLoading from "components/SN/SNFormLoading";
import { successAlert } from "lib/alertHelper";
import path from "screens/Root/routePaths";

import SubTemplateForm from "./components/SubTemplateForm";
import subService from "../service";


const SubcriptionForm = (props) => {
  const { history, match } = props;
  const [form] = Form.useForm();

  const { sub_id } = match.params;

  const [isFormChanged, setIsFormChanged] = useState(false);

  let initialValues: any = {
    billing_period: 0,
    price: 0,
    free_trial_days: 7,
    status: 2,
    invoice_terms: 15,
  };

  const [editData] = subService.useSubscriptionDetails(sub_id);
  if (sub_id && !editData) return <SNFormLoading />;

  if (editData) {
    const {
      company,
      plan,
      bill_to,
      start_date,
      billing_period,
      price,
      invoice_terms,
      status,
      note,
    } = editData;

    initialValues = {
      company: { value: company?.id || null },
      plan: { value: plan?.id || null },
      start_date: start_date && dayjs(start_date),
      billing_period,
      price,
      invoice_terms,
      status,
      note,
      contact_email: bill_to?.contact?.email || null,
      contact_first_name: bill_to?.first_name || null,
      contact_last_name: bill_to?.first_name || null,
      contact_phone1: bill_to?.contact?.phone1 || null,
      contact_phone2: bill_to?.contact?.phone2 || null,
      contact_address: bill_to?.address || null,
    };
  }

  const onFinish = (values) => {
    const {
      company,
      plan,
      contact_email,
      contact_first_name,
      contact_last_name,
      contact_phone1,
      contact_phone2,
      contact_address,
      start_date,
      billing_period,
      price,
      invoice_terms,
      on_end,
      status,
      note,
    } = values;

    let formData = {
      company: company.value ?? null,
      plan: plan.value ?? null,
      billing_period,
      price,
      invoice_terms,
      on_end,
      status: editData ? editData.status : status,
      note,
      start_date: start_date?.format("YYYY-MM-DD") || null,
      bill_to:
        contact_first_name ||
        contact_last_name ||
        contact_phone1 ||
        contact_phone2 ||
        contact_email ||
        contact_address
          ? {
              first_name: contact_first_name,
              last_name: contact_last_name,
              contact: {
                phone1: contact_phone1,
                phone2: contact_phone2,
                email: contact_email,
              },
              address: contact_address,
            }
          : null,
    };

    if (sub_id) {
      subService.editSubscription(sub_id, formData, () => {
        setIsFormChanged(false);
        history.goBack();
        successAlert("Subcription has been edited");
      });
    } else {
      subService.addSubscription(formData, () => {
        setIsFormChanged(false);
        history.push(path.all_subscriptions);
        successAlert("Subcription has been created");
      });
    }
  };

  const handleOnChange = (changedField, allFields) => {
    if (!isFormChanged) setIsFormChanged(true);
  };

  return (
    <SNPanel
      panelTitle={sub_id ? "Edit subscription" : "Create new subscription"}
    >
      <Prompt
        when={isFormChanged}
        message={
          "You may have unsaved data on this form. Leaving this form will discard all the changes!"
        }
      />
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={initialValues}
        className="sn-form"
        onValuesChange={handleOnChange}
      >
        <SubTemplateForm form={form} />

        <Row>
          <Col span={24} style={{ textAlign: "right" }}>
            <Space size={8}>
              <SNButton
                title="Cancel"
                className="sn-btn-big"
                onClick={() => {
                  history.goBack();
                }}
              />
              <SNButton
                title="Save"
                type="primary"
                className="sn-btn-big"
                onClick={form.submit}
              />
            </Space>
          </Col>
        </Row>
      </Form>
    </SNPanel>
  );
};

export default SubcriptionForm;
