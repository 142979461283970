import Axios from "axios";
import { useEffect, useState } from "react";
import api, { url } from "../../../api";
import { Plan } from "./data.t";

const usePlanDetails = (id: any): [Plan, Function] => {
  const [planData, setPlanData] = useState<Plan>(null);
  const [willReload, setWillReload] = useState<boolean>(false);

  let source = Axios.CancelToken.source();
  const reload = () => {
    setPlanData(null);
    setWillReload(!willReload);
  };

  const fetchDetails = () => {
    api.get(`${url.SUBSCRIPTION_PLAN}${id}/`).then((res) => {
      setPlanData(res.data);
    });
  };

  useEffect(() => {
    if (id) {
      fetchDetails();
    } else {
      setPlanData(null);
    }
    return () => {
      source.cancel("Cancelling in cleanup");
    };
  }, [id, willReload]);

  return [planData, reload];
};
function getAvailableSettings(): any {
  return api.get(`${url.SUBSCRIPTION_PLAN}entity/available/`);
}

function addPlan(params: any = {}, postActions?: (data: any) => void) {
  api.post(url.SUBSCRIPTION_PLAN, params).then((response) => {
    if (response.status === 201) {
      if (postActions) postActions(response.data);
    }
  });
}

function editPlan(
  id: any,
  params: any = {},
  postActions?: (data: any) => void
) {
  api.put(`${url.SUBSCRIPTION_PLAN}${id}/`, params).then((response) => {
    if (response.status === 200) {
      if (postActions) postActions(response.data);
    }
  });
}

const planService = {
  usePlanDetails,
  getAvailableSettings,
  addPlan,
  editPlan,
};

export default planService;
