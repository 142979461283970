import {
  blue
} from "@ant-design/colors";
import { Tag } from "antd";
import {
  planBillingPeriod,
  PlanBillingType,
  planEntity,
  planStatus,
  PlanStatusType,
} from "./data.t";

export const getPlanStatusTag = (status: PlanStatusType) => {
  switch (status) {
    case 1:
      return <Tag color={"success"}>{planStatus[1]}</Tag>;
    case 2:
      return <Tag color={"error"}>{planStatus[2]}</Tag>;
    default:
      return null;
  }
};
export const getTypeTag = (type: number) => {
  switch (type) {
    case 1:
      return <Tag color="#69c0ff">Limited Based</Tag>;
    case 2:
      return <Tag color="#69c0ff">Limited Entity</Tag>;
    default:
      return null;
  }
};
export const getPlanBillingTag = (status: PlanBillingType) => {
  switch (status) {
    case 0:
      return <Tag color={"purple"}>{planBillingPeriod[0]}</Tag>;
    case 1:
      return <Tag color={"volcano"}>{planBillingPeriod[1]}</Tag>;
    case 2:
      return <Tag color={"warning"}>{planBillingPeriod[2]}</Tag>;
    case 3:
      return <Tag color={"gold"}>{planBillingPeriod[3]}</Tag>;
    default:
      return null;
  }
};

export const getPlanEntityObj = (entity: any) => {
  switch (entity) {
    case "asset":
      return {
        label: planEntity["asset"],
        color: blue.primary,
      };
    case "invoice":
      return {
        label: planEntity["invoice"],
        color: blue.primary,
      };
    case "workorder":
      return {
        label: planEntity["workorder"],
        color: blue.primary,
      };
    case "snode":
      return {
        label: planEntity["snode"],
        color: blue.primary,
      };
    case "form":
      return {
        label: planEntity["form"],
        color: blue.primary,
      };
    case "customer":
      return {
        label: planEntity["customer"],
        color: blue.primary,
      };
    case "place":
      return {
        label: planEntity["place"],
        color: blue.primary,
      };
    case "companyusermap":
      return {
        label: planEntity["companyusermap"],
        color: blue.primary,
      };
    default:
      return {
        label: "",
        color: "",
      };
  }
};

