import ProTable, {
  ActionType,
  ProColumns,
  RequestData,
} from "@ant-design/pro-table";
import { Col, DatePicker, Row } from "antd";
import { FormInstance } from "antd/lib/form";
import { useEffect, useRef, useState } from "react";
import api, { url } from "../../../../../api";
import SNFormItem from "../../../../../components/SN/SNForm/SNFormItem";
import SNInput from "../../../../../components/SN/SNForm/SNInput";
import SNTextarea from "../../../../../components/SN/SNForm/SNTextarea";
import SNTitle from "../../../../../components/SN/SNTitle";
import { formatDate, generateEndNodeId } from "../../../../../lib";
import {
  CompanyDataApi,
  CompanyNodesListItem,
} from "../../../../Companies/data.t";
import SNCompaniesSelect from "../../../../components/EntitiesSelect/SNCompaniesSelect";
import NodeGenerator from "../../../components/NodeGenerator";

interface NodeAllocateTemplateFormProps {
  form: FormInstance;
  editData?: any;
  isLock?: boolean;
}

function NodeAllocateTemplateForm(props: NodeAllocateTemplateFormProps) {
  const { form } = props;
  const listRef = useRef<ActionType>(null);
  const [selectedCompany, setSelectedCompany] = useState<CompanyDataApi>(null);

  useEffect(() => {
    if (selectedCompany) {
      listRef.current?.reload();
    }
  }, [selectedCompany]);

  const renderNodeInfo = () => {
    return (
      <section>
        <SNTitle icon={["fal", "qrcode"]} title="Node information" />

        <SNCompaniesSelect
          name="company"
          placeholdertext="Select company in the system"
          label="Company"
          required
          onChange={(value, option: any) => {
            if (value) {
              setSelectedCompany(option.data);
            } else {
              setSelectedCompany(null);
            }
          }}
        />

        <NodeGenerator form={form} />

        <SNFormItem label="Ordered at" name="ordered_at">
          <DatePicker />
        </SNFormItem>
      </section>
    );
  };

  const renderGeneralInfo = () => {
    return (
      <section>
        <SNTitle icon={["fal", "user"]} title="Contact infomation" />
        <Row gutter={20}>
          <Col span={12}>
            <SNFormItem
              label="First name"
              name="first_name"
              rules={[{ max: 50 }]}
            >
              <SNInput />
            </SNFormItem>
          </Col>
          <Col span={12}>
            <SNFormItem
              label="Last name"
              name="last_name"
              rules={[{ max: 50 }]}
            >
              <SNInput />
            </SNFormItem>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={12}>
            <SNFormItem label="Phone 1" name="phone1" rules={[{ max: 50 }]}>
              <SNInput />
            </SNFormItem>
          </Col>
          <Col span={12}>
            <SNFormItem label="Phone 2" name="phone2" rules={[{ max: 50 }]}>
              <SNInput />
            </SNFormItem>
          </Col>
        </Row>
        <SNFormItem label="Email" name="email">
          <SNInput />
        </SNFormItem>

        <SNTitle icon={["fal", "info"]} title="Description" />
        <SNFormItem name="description">
          <SNTextarea placeholder="Add a short description (Optional)" />
        </SNFormItem>
      </section>
    );
  };

  const renderAllocateList = () => {
    const listColumns: ProColumns<CompanyNodesListItem>[] = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Allocated on",
        dataIndex: "created_at",
        key: "created_at",
        render: (value) => formatDate(value, "YYYY-MM-DD, HH:mm"),
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
      },
      {
        title: "Start node",
        key: "start_node",
        render: ({ initial, number, series }: CompanyNodesListItem) => (
          <span>{`${initial}${number}${series}`}</span>
        ),
      },
      {
        title: "End node",
        key: "end_node",
        render: ({ initial, number, total, series }: CompanyNodesListItem) => (
          <span>{generateEndNodeId(initial, number, total, series)}</span>
        ),
      },
    ];

    return (
      <section>
        <SNTitle
          icon={["fal", "qrcode"]}
          title={`${selectedCompany.company_name} - Node allocation`}
        />
          <ProTable<CompanyNodesListItem>
            actionRef={listRef}
            request={async (
              { pageSize = 50, current, keyword },
              sort,
              filter
            ): Promise<RequestData<CompanyNodesListItem>> => {
              const sortKey = Object.keys(sort).length
                ? Object.keys(sort)[0]
                : "";
              const sortParams = sortKey.length
                ? `&ordering=${
                    sort[sortKey] === "descend" ? "-" : ""
                  }${sortKey}`
                : "";

              const urlRequest = `${
                url.NODE_ALLOCATION
              }?limit=${pageSize}&offset=${
                (current - 1) * pageSize
              }${sortParams}&company=${selectedCompany.id}`;

              const res = await api.get(urlRequest);

              return {
                data: res.data.results,
                success: true,
                total: res.data.count,
              };
            }}
            search={false}
            options={false}
            size={"small"}
            pagination={false}
            rowKey={(key) => key.id}
            columns={listColumns}
            dateFormatter="string"
          />
      </section>
    );
  };

  return (
    <div>
      <Row gutter={[40, 16]}>
        <Col xl={8} lg={12} md={12} xs={24}>
          {renderNodeInfo()}
        </Col>

        <Col xl={8} lg={12} md={12} xs={24}>
          {renderGeneralInfo()}
        </Col>
      </Row>

      {selectedCompany && (
        <Row gutter={[40, 16]}>
          <Col xl={24} lg={24} md={24} xs={24}>
            {renderAllocateList()}
          </Col>
        </Row>
      )}
    </div>
  );
}

export default NodeAllocateTemplateForm;
