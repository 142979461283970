import { Col, Form, Row } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { FC } from "react";
import Validator from "validator";
import SNButton from "../../../../../components/SN/SNButton";
import SNFormItem from "../../../../../components/SN/SNForm/SNFormItem";
import SNInput from "../../../../../components/SN/SNForm/SNInput";
import SNSelect from "../../../../../components/SN/SNForm/SNSelect";
import SNIcon from "../../../../../components/SN/SNIcon";
import SNText from "../../../../../components/SN/SNText";

interface InvoiceItemSectionFormProps {
  form: FormInstance;
  name: string;
}

export const initInvoiceItems = {
  category: 'subscription',
  description: "",
  quantity: 0,
  unit: null,
  unit_price: 0,
  vat_percent: 0,
  discount: 0,
  total_entity: 0,
};

const InvoiceItemSectionForm: FC<InvoiceItemSectionFormProps> = (props) => {
  const { name } = props;

  const validateMessages = {
    unit_price: {
      validator: (_, value) =>
        Validator.isNumeric(value.toString()) && value >= 0
          ? Promise.resolve()
          : Promise.reject(new Error("Not valid number")),
    },
    quantity: {
      validator: (_, value) =>
        Validator.isNumeric(value.toString(), { no_symbols: true })
          ? Promise.resolve()
          : Promise.reject(new Error("Not valid number")),
    },
  };

  return (
    <div>
      <Row gutter={[12, 16]} style={{ marginBottom: 20 }}>
        <Col xl={4} lg={8} md={8} xs={8}>
          <SNText text="Category" />
        </Col>
        <Col xl={3} lg={6} md={6} xs={6}>
          <SNText text="Description" />
        </Col>
        <Col xl={2} lg={4} md={4} xs={4}>
          <SNText text="Quantity" />
        </Col>
        <Col xl={3} lg={7} md={7} xs={7}>
          <SNText text="Unit" />
        </Col>
        <Col xl={3} lg={7} md={7} xs={7}>
          <SNText text="Price/qty (SEK)" />
        </Col>
        <Col xl={2} lg={4} md={4} xs={4}>
          <SNText text="VAT" />
        </Col>
        <Col xl={3} lg={7} md={7} xs={7}>
          <SNText text="Discount (SEK)" />
        </Col>
        <Col xl={3} lg={7} md={7} xs={7}>
          <SNText text="Item total (SEK)" />
        </Col>
      </Row>

      <Form.List name={name}>
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, ...restField }) => {
              return (
                <Row key={key} gutter={[12, 16]} style={{ marginBottom: 10 }}>
                  <Col xl={4} lg={8} md={8} xs={8}>
                    <SNFormItem
                      {...restField}
                      name={[name, "category"]}
                      isListField={true}
                      validateTrigger={["onChange", "onBlur"]}
                    >
                      <SNSelect
                        options={[
                          { value: 'subscription', label: 'Subscription' },
                          { value: 'setup_fee', label: 'Setup fee' },
                          { value: 'other', label: 'Other' },
                        ]}
                        allowClear={false}
                      />
                    </SNFormItem>
                  </Col>
                  <Col xl={3} lg={6} md={6} xs={6}>
                    <SNFormItem
                      {...restField}
                      name={[name, "description"]}
                      isListField={true}
                      validateTrigger={["onChange", "onBlur"]}
                      noStyle
                    >
                      <SNInput style={{ width: "100%" }} />
                    </SNFormItem>
                  </Col>
                  <Col xl={2} lg={4} md={4} xs={4}>
                    <SNFormItem
                      {...restField}
                      name={[name, "quantity"]}
                      isListField={true}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[validateMessages.quantity]}
                    >
                      <SNInput
                        min={0}
                        style={{ width: "100%" }}
                        maxLength={20}
                        type="number"
                      />
                    </SNFormItem>
                  </Col>
                  <Col xl={3} lg={7} md={7} xs={7}>
                    <SNFormItem
                      {...restField}
                      name={[name, "unit"]}
                      isListField={true}
                      validateTrigger={["onChange", "onBlur"]}
                    >
                      <SNSelect
                        options={[
                          { value: "pcs", label: "Pieces (pcs)" },
                          { value: "hrs", label: "Hours (hrs)" },
                          { value: "min", label: "Minutes (min)" },
                          { value: "g", label: "Gram (g)" },
                          { value: "hg", label: "Hektogram (hg)" },
                          { value: "kg", label: "Kilogram (kg)" },
                          { value: "lbs", label: "Pound (lbs)" },
                          { value: "ton", label: "Ton (ton)" },
                          { value: "mm", label: "Millimeter (mm)" },
                          { value: "cm", label: "Centimeter (cm)" },
                          { value: "dm", label: "Decimeter (dm)" },
                          { value: "m", label: "Meter (m)" },
                          { value: "inch", label: "Inch (inch)" },
                          { value: "ft", label: "Foot (ft)" },
                          { value: "m2", label: "Square meter (m2)" },
                          { value: "m3", label: "Cubic meter (m3)" },
                          { value: "cl", label: "Centiliter (cl)" },
                          { value: "ml", label: "Milliliter (ml)" },
                          { value: "dl", label: "Deciliter (dl)" },
                          { value: "l", label: "Liter (l)" },
                          {value: "month", label: "Month (month)"},
                          {value: "year", label: "Year (year)"}
                        ]}
                        allowClear={true}
                      />
                    </SNFormItem>
                  </Col>
                  <Col xl={3} lg={7} md={7} xs={7}>
                    <SNFormItem
                      {...restField}
                      name={[name, "unit_price"]}
                      rules={[validateMessages.unit_price]}
                      isListField={true}
                      validateTrigger={["onChange", "onBlur"]}
                    >
                      <SNInput
                        min={0}
                        style={{ width: "100%" }}
                        placeholder={"0.00"}
                        type="number"
                      />
                    </SNFormItem>
                  </Col>
                  <Col xl={2} lg={4} md={4} xs={4}>
                    <SNFormItem
                      {...restField}
                      name={[name, "vat_percent"]}
                      isListField={true}
                      validateTrigger={["onChange", "onBlur"]}
                    >
                      <SNSelect
                        options={[
                          {
                            value: 0,
                            label: "0%",
                          },
                          {
                            value: 6,
                            label: "6%",
                          },
                          {
                            value: 12,
                            label: "12%",
                          },
                          {
                            value: 25,
                            label: "25%",
                          },
                        ]}
                        allowClear={false}
                      />
                    </SNFormItem>
                  </Col>
                  <Col xl={3} lg={7} md={7} xs={7}>
                    <SNFormItem
                      {...restField}
                      name={[name, "discount"]}  
                      isListField={true}
                      validateTrigger={["onChange", "onBlur"]}
                    >
                      <SNInput
                        min={0}
                        style={{ width: "100%" }}
                        placeholder="0.00"
                        type="number"
                      />
                    </SNFormItem>
                  </Col>
                  <Col xl={3} lg={7} md={7} xs={7}>
                    <SNFormItem
                      name={[name, "total"]}
                      isListField={true}
                      validateTrigger={["onChange", "onBlur"]}
                    >
                      <SNInput style={{ width: "100%" }} disabled />
                    </SNFormItem>
                  </Col>
                  {key > 0 && (
                    <Col xl={1} lg={1} md={1} xs={1}>
                      <SNIcon
                        icon="minus-circle"
                        className="dynamic-delete-button"
                        style={{ marginTop: 1 }}
                        onClick={() => {
                          remove(name);
                        }}
                      />
                    </Col>
                  )}
                </Row>
              );
            })}
            <SNFormItem>
              <SNButton
                type="dashed"
                onClick={() => {
                  add(initInvoiceItems);
                }}
                style={{ width: "100%", top: 15 }}
                icon="plus"
                title="Add item"
              />

              <Form.ErrorList errors={errors} />
            </SNFormItem>
          </>
        )}
      </Form.List>
    </div>
  );
};

export default InvoiceItemSectionForm;
