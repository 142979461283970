import { Col, DatePicker, Row, Table, Tag } from "antd";
import { FormInstance } from "antd/lib/form";
import { useState } from "react";
import SNDescription from "components/SN/SNDescription";
import SNAddress from "components/SN/SNForm/SNAddress";
import SNFormItem from "components/SN/SNForm/SNFormItem";
import SNInput from "components/SN/SNForm/SNInput";
import SNSelect from "components/SN/SNForm/SNSelect";
import SNTextarea from "components/SN/SNForm/SNTextarea";
import SNPriceInput from "components/SN/SNPriceInput";
import SNText from "components/SN/SNText";
import SNTitle from "components/SN/SNTitle";
import SNCompaniesSelect from "../../../../components/EntitiesSelect/SNCompaniesSelect";
import SNSubPlansSelect from "../../../../components/EntitiesSelect/SNSubPlansSelect";
import {
  getPlanBillingTag,
  getPlanStatusTag,
} from "../../../Plans/const";
import { planBillingPeriod, planEntity } from "../../../Plans/data.t";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";

interface PlanTemplateFormProps {
  form: FormInstance;
  editData?: any;
  isLock?: boolean;
}

const SubTemplateForm = (props: PlanTemplateFormProps) => {
  const { form } = props;
  const [selectedPlan, setSelectedPlan] = useState(null);

  const renderGeneralInfo = () => {
    return (
      <section>
        <SNTitle icon={["fal", "info-square"]} title="General information" />

        <SNCompaniesSelect
          name="company"
          label="Company"
          placeholdertext="Select company in the system"
          required
        />

        <SNSubPlansSelect
          name="plan"
          label="Plan"
          placeholdertext="Plan for this subscription"
          required
          onChange={(value, option: any) => {
            if (value) {
              setSelectedPlan(option.data);
              form.setFieldsValue({
                billing_period: option.data.billing_period,
                price: option.data.price,
              });
            } else {
              setSelectedPlan(null);
            }
          }}
        />

        <SNFormItem label="Billing period" name="billing_period">
          <SNSelect
            options={Object.keys(planBillingPeriod).map((item) => {
              return {
                label: planBillingPeriod[item],
                value: parseInt(item),
              };
            })}
            allowClear={false}
            onChange={(value) => {
              if (value === 0) {
                form.setFieldsValue({ price: 0 });
              }
            }}
          />
        </SNFormItem>

        <SNPriceInput
          form={form}
          label="Price / billing period"
          name="price"
          required
        />

        <SNFormItem label="Start date" name="start_date" required>
          <DatePicker />
        </SNFormItem>
        <SNFormItem label="On end" name="on_end" required>
          <SNSelect
            options={[
              { label: "Auto renew", value: 1 },
              { label: "Do not renew", value: 2 },
            ]}
          />
        </SNFormItem>
        <SNFormItem label="Free trial days" name="free_trial_days">
          <SNSelect
            options={[
              { label: "No trial", value: 0 },
              { label: "7 days", value: 7 },
              { label: "14 days", value: 14 },
              { label: "30 days", value: 30 },
            ]}
          />
        </SNFormItem>
      </section>
    );
  };

  const renderInvoiceInfo = () => {
    return (
      <section>
        <SNTitle icon={["fal", "file-invoice"]} title="Invoice information" />

        <SNFormItem label="Pay terms" name="invoice_terms">
          <SNSelect
            options={[
              { label: "NET 15", value: 15 },
              { label: "NET 30", value: 30 },
            ]}
          />
        </SNFormItem>

        <SNFormItem label="Invoice Ref" name="invoice_ref">
          <SNInput maxLength={50} />
        </SNFormItem>

        <SNFormItem label="Invoice email" name="invoice_email">
          <SNInput maxLength={50} />
        </SNFormItem>

        <SNTitle icon={["fal", "map-marked-alt"]} title="Invoice address" />
        <SNAddress name="contact_address" form={form} />
      </section>
    );
  };

  const renderContactInfo = () => {
    return (
      <section>
        <SNTitle icon={["fal", "info"]} title="Other infomation" />

        <SNFormItem name="note" label="Note">
          <SNTextarea
            placeholder="Note to the customer for this subscription"
            autoSize={{ minRows: 5, maxRows: 5 }}
          />
        </SNFormItem>
      </section>
    );
  };

  const renderPlanReview = () => {
    if (!selectedPlan) return null;
    const { id, name, code, is_public, status, billing_period } = selectedPlan;

    return (
      <section>
        <SNTitle icon={["fal", "info-circle"]} title={`Plan #${id} (Review)`} />
        <SNDescription
          title={null}
          icon={["fal", "info"]}
          bordered
          column={1}
          rowSize={["15%", "35%"]}
          size={"small"}
          itemData={[
            {
              label: "Plan name",
              value: name,
            },
            {
              label: "Plan code",
              value: code,
            },
            {
              label: "Billing period",
              value: getPlanBillingTag(billing_period),
            },
            {
              label: "Status",
              value: getPlanStatusTag(status),
            },
            {
              label: "Public",
              value: is_public ? "Yes" : "NO",
            },
          ]}
        />
      </section>
    );
  };

  const renderPlanFeature = () => {
    if (!selectedPlan) return null;

    return (
      <section>
        <SNTitle
          icon={["fal", "cogs"]}
          title="Features"
          extra={
            <span>
              <SNText strong text={"Total price: "} />
              <Tag color={"default"}> (SEK)</Tag>
            </span>
          }
        />

        <Table
          dataSource={selectedPlan.plan_settings ?? []}
          pagination={false}
          columns={[
            {
              title: "Entity",
              dataIndex: "entity",
              key: "name",
              render: (value) => {
                return (
                  <SNText
                    strong
                    text={planEntity[value] ?? "Unknown"}
                  />
                );
              },
            },
            {
              title: "Available",
              dataIndex: "available",
              key: "available",
              render: (text) =>
                text ? (
                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                ) : (
                  <CloseCircleTwoTone twoToneColor="#eb2f96" />
                ),
            },
            
            {
              title: "Quantity",
              dataIndex: "entity_limit",
              key: "entity_limit",
              render: (value) => (value === 0 ? "Unlimited" : value),
            },
            {
              title: "Price per entity",
              dataIndex: "entity_price",
              key: "entity_price",
              render: (value) => `${value} (SEK)`,
            },
          ]}
        />
      </section>
    );
  };

  return (
    <>
      <Row gutter={[40, 16]}>
        <Col xl={8} lg={16} md={16} xs={24}>
          {renderGeneralInfo()}
        </Col>
        <Col xl={8} lg={16} md={16} xs={24}>
          {renderInvoiceInfo()}
        </Col>
        <Col xl={8} lg={16} md={16} xs={24}>
          {renderContactInfo()}
        </Col>
      </Row>
      <Row gutter={[40, 16]} style={{ marginBottom: 20 }}>
        <Col xl={8} lg={16} md={16} xs={24}>
          {renderPlanReview()}
        </Col>
        <Col xl={16} lg={24} md={24} xs={24}>
          {renderPlanFeature()}
        </Col>
      </Row>
    </>
  );
};

export default SubTemplateForm;
