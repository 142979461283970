import { SearchOutlined } from "@ant-design/icons";
import ProTable, {
  ActionType,
  ProColumns,
  RequestData,
} from "@ant-design/pro-table";
import { Button, Col, Input } from "antd";
import { TablePaginationConfig } from "antd/lib/table";
import { ColumnType } from "antd/lib/table/interface";
import { useContext, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import api, { url } from "../../../../api";
import SNText from "../../../../components/SN/SNText";
import SNTitle from "../../../../components/SN/SNTitle";
import { UserStatus } from "../../../../components/Status";
import { CompanyUserListApi } from "../../data.t";
import CompanyDetailsContext from "../context";

const UserTab = () => {
  const { companyId } = useContext(CompanyDetailsContext);
  const searchInputRef = useRef<any>();
  const listRef = useRef<ActionType>();
  const [search, setSearch] = useState<string>("");
  const [searchColumn, setSearchColumn] = useState<string>("");
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: 100,
    total: 0,
    showQuickJumper: true,
  });

  const _handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearch(selectedKeys[0]);
    setSearchColumn(dataIndex);
    setPagination({ ...pagination, current: 1, pageSize: 50, total: 0 });
    listRef.current?.reload();
  };

  const _handleReset = (clearFilters) => {
    clearFilters();
    setSearch("");
    setSearchColumn("");
    listRef.current?.reload();
  };

  /**
   * Render view region
   */

  const _getColumnSearchProps = (
    dataIndex: string,
    title: string
  ): Pick<
    ColumnType<CompanyUserListApi>,
    | "filterDropdown"
    | "filterIcon"
    | "onFilter"
    | "onFilterDropdownVisibleChange"
    | "render"
  > => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputRef}
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => _handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => _handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => _handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(typeof value === "string" && value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInputRef.current?.select());
      }
    },
    render: (text) =>
      searchColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[search]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const _renderUserList = () => {
    const columns: ProColumns<CompanyUserListApi>[] = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Email",
        dataIndex: "username",
        key: "username",
        ..._getColumnSearchProps("username", "Username"),
        render: (value: any) => <SNText text={value} />,
      },
      {
        title: "First name",
        dataIndex: "first_name",
        key: "first_name",
        ..._getColumnSearchProps("first_name", "First name"),
        render: (value: any) => <SNText text={value} />,
      },
      {
        title: "Last name",
        dataIndex: "last_name",
        key: "last_name",
        ..._getColumnSearchProps("last_name", "Last name"),
        render: (value: any) => <SNText text={value} />,
      },
      {
        title: "Last login",
        dataIndex: "last_login",
        key: "last_login",
        valueType: "dateTime",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (status) => <UserStatus status={(status = 2 ? true : false)} />,
      },
    ];

    return (
      <Col xl={24} lg={24} md={24} xs={24} className="sn-card-content-body top">
        <SNTitle icon={"list-ol"} title={"User List"} />
        <>
          <ProTable<CompanyUserListApi>
            actionRef={listRef}
            className="sn-table-inside-card"
            request={async (
              { pageSize, current, keyword },
              sort,
              filter
            ): Promise<RequestData<CompanyUserListApi>> => {
              const sortKey = Object.keys(sort).length
                ? Object.keys(sort)[0]
                : "";
              const sortParams = sortKey.length
                ? `&ordering=${
                    sort[sortKey] === "descend" ? "-" : ""
                  }${sortKey}`
                : "";
              const searchParams = search ? `&${searchColumn}=${search}` : "";

              const urlRequest = `${
                url.COMPANY_USER
              }?limit=${pageSize}&offset=${
                (current - 1) * pageSize
              }${sortParams}${searchParams}&company=${companyId}`;

              const res = await api.get(urlRequest);

              const paging: TablePaginationConfig = {
                ...pagination,
                total: res.count,
              };
              setPagination(paging);

              return {
                data: res.data.results,
                success: true,
                total: res.data.count,
              };
            }}
            search={false}
            options={{
              setting: false,
              fullScreen: false,
              density: false,
            }}
            size={"small"}
            pagination={pagination}
            rowKey={(key) => key.id}
            columns={columns}
            dateFormatter="string"
          />
        </>
      </Col>
    );
  };

  return <>{_renderUserList()}</>;
};

export default UserTab;
