import { LogoutOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Menu, Space, message } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { removeAuth, removeUser } from "../../redux/Reducers/authReducer";
import styles from "./index.scss";

const AvatarDropdown = (props) => {
  const { history } = props;
  const dispatch = useDispatch();
  const admin = useSelector((state: any) => state.persistReducers.auth.user);
  const { username } = admin;

  const _handleLogout = () => {
    dispatch(removeAuth());
    dispatch(removeUser());
    message.success("See you, Admin!", 1);
    history.push("/login");
  };

  const menuHeaderDropdown = (
    <Menu className={"menu"} selectedKeys={[]}>
      <Menu.Item key="logout" onClick={_handleLogout}>
        <LogoutOutlined /> Log out
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menuHeaderDropdown} placement="bottomRight">
      <Space align="center" className={`${styles.action} ${styles.account}`} size={"middle"}>
        <Avatar
          size="small"
          className={"avatar"}
          src={
            "https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png"
          }
          alt="avatar"
        />
        <span className={`${styles.name} anticon`}>{username}</span>
      </Space>
    </Dropdown>
  );
};

export default AvatarDropdown;
