import { Col, Form, Row, Space } from "antd";
import { useState } from "react";
import { Prompt } from "react-router-dom";
import SNFormLoading from "components/SN/SNFormLoading";

import SNButton from "components/SN/SNButton";
import SNPanel from "components/SN/SNPanel";
import { successAlert } from "lib/alertHelper";
import path from "screens/Root/routePaths";

import PlanTemplateForm from "./components/PlanTemplateForm";
import planService from "../service";
import { planBillingPeriod } from "../data.t";
const PlanForm = (props) => {
  const { history, match } = props;
  const [form] = Form.useForm();

  const { plan_id } = match.params;

  const [isFormChanged, setIsFormChanged] = useState(false);

  let initialValues: any = {
    free_trial_day: 0,
    billing_period: 0,
    price: 0,
    is_public: false,
    plan_price: 0,
  };

  const [editData] = planService.usePlanDetails(plan_id);
  if (plan_id && !editData) return <SNFormLoading />;

  if (editData) {
    initialValues = {
      ...editData,
      billing_period: planBillingPeriod.hasOwnProperty(editData.billing_period)
        ? editData.billing_period
        : 0,
      setting_price: editData.plan_settings.length
        ? editData.plan_settings
            .map((x) => x.price ?? "0")
            .reduce((a, b) => (parseFloat(a) + parseFloat(b)).toFixed(2))
        : "0",
    };
  }

  const onFinish = (values) => {
    const formData = {
      ...values,
    };    
    if (plan_id) {
      planService.editPlan(plan_id, formData, () => {
        setIsFormChanged(false);
        history.goBack();
        successAlert("Subcription plan has been edited");
      });
    } else {
      planService.addPlan(formData, () => {
        setIsFormChanged(false);
        history.push(path.all_sub_plans);
        successAlert("Subcription plan has been created");
      });
    }
  };

  const handleOnChange = (changedField, allFields) => {
    const { plan_settings } = allFields;

    const newSettings = plan_settings
      ? plan_settings.map((item) => {
          if (item.entity_limit && item.entity_price) {
            return {
              ...item,
              price: `${(
                parseFloat(item.entity_limit) * parseFloat(item.entity_price)
              ).toFixed(2)}`,
            };
          }

          return {
            ...item,
          };
        })
      : [];

    form.setFieldsValue({ plan_settings: newSettings });
    form.setFieldsValue({
      setting_price: newSettings
        .map((x) => x.price ?? 0)
        .reduce((a, b) => (parseFloat(a) + parseFloat(b)).toFixed(2), 0),
    });
    if (!isFormChanged) setIsFormChanged(true);
  };

  return (
    <SNPanel panelTitle={plan_id ? "Edit plan" : "Create new plan"}>
      <Prompt
        when={isFormChanged}
        message={
          "You may have unsaved data on this form. Leaving this form will discard all the changes!"
        }
      />
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={initialValues}
        className="sn-form"
        onValuesChange={handleOnChange}
      >
        <PlanTemplateForm form={form} />

        <Row style={{ marginTop: 20 }}>
          <Col span={24} style={{ textAlign: "right" }}>
            <Space size={8}>
              <SNButton
                title="Cancel"
                className="sn-btn-big"
                onClick={() => {
                  history.goBack();
                }}
              />
              <SNButton
                title="Save"
                type="primary"
                className="sn-btn-big"
                onClick={form.submit}
              />
            </Space>
          </Col>
        </Row>
      </Form>
    </SNPanel>
  );
};

export default PlanForm;
