// list entity type
//   "asset",
//   "assetsharing",
//   "entityform",
//   "error",
//   "invoice",
//   "snode",
//   "parts",
//   "place",
//   "checklist",
//   "service",
//   "serviceplan",
//   "timelog",
//   "workorder"

export type PlanStatusType = 1 | 2;



export type PlanEntityType =
  | "workorder"
  | "asset"
  | "snode"
  | "place"
  | "invoice"
  | "form"


export type PlanBillingType = 0 | 1 | 2 | 3;

export type PlanSetting = {
  id: number;
  plan: number;
  entity: PlanEntityType;
  type: number;
  entity_limit: number;
  entity_price: string; // float number
  price: string; // float number
};

export interface Plan {
  id: number;
  name: string;
  code: string;
  billing_period: PlanBillingType;
  description: string;
  is_public: boolean;
  status: PlanStatusType;
  plan_settings: PlanSetting[];
  price: string; // float number,
  created_by: any;
  created_at: string;
  invoice_items: any[];
  setup_fee: string; // float number
  free_trial_day: number;
}

export const planBillingPeriod: { [key in PlanBillingType]: string } = {
  0: "No billing",
  1: "Monthly",
  2: "Quarterly",
  3: "Annually",
};

export const planStatus: { [key in PlanStatusType]: string } = {
  1: "Active",
  2: "Inactive",
};

// [
//   "asset",
//   "assetsharing",
//   "entityform",
//   "error",
//   "invoice",
//   "snode",
//   "parts",
//   "place",
//   "checklist",
//   "service",
//   "serviceplan",
//   "timelog",
//   "workorder"
// ]

export const planEntity = {
  asset: "Asset",
  workorder: "Work order",
  snode: "QR Code",
  place: "Place",
  invoice: "Invoice",
  entityform: "Form",
  assetsharing: "Asset sharing",
  error: "Error",
  parts: "Parts",
  checklist: "Checklist",
  service: "Service",
  serviceplan: "Service Plan",
  timelog: "Time log"
};
