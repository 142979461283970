import { AddressApi } from "./../../constants/system";
import { CompanyDataApi } from "./../Companies/data.t";
import { Plan, PlanBillingType } from "./Plans/data.t";

export type SubStatusType = 1 | 2 | 3 | 4; // 1. On hold (Default) | 2. Active | 3. Inactive | 4. Cancelled

export interface SubscriptionApi {
  id: number;
  company: CompanyDataApi;
  name: string;
  plan: Plan;
  start_date: string;
  billing_period: PlanBillingType;
  price: string; // float number
  invoice_terms: number;
  free_trial_day: number;
  note: string;
  status: SubStatusType;
  bill_to: {
    first_name: string;
    last_name: string;
    contact: {
      phone1: string;
      phone2: string;
      email: string;
    };
    address: AddressApi;
  };
  cancelled: boolean;
  cancel_date: string;
  created_by: any;
  created_at: string;
  on_end: number;
  currency: string;
}

export const subStatus: { [key in SubStatusType]: string } = {
  1: "On hold", // Default
  2: "Active",
  3: "Inactive",
  4: "Cancelled",
};


export interface BillingPeriodData {
  id?: number;
  name: string;
  percent_discount: number;
  code: string;
  description: string;
}

export interface Invoice{ 
  id: number;
  number: string;
  date: string;
  due_date: string;
  total: number;
  amount_paid: number;
  status: SubStatusType;
  created_at: string;
  updated_at: string;
  subscription: SubscriptionApi;
}