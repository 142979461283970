import { Col, DatePicker, Descriptions, FormInstance, Row } from "antd";
import { FC, useState } from "react";
import SNAddress from "../../../../../components/SN/SNForm/SNAddress";
import SNFormItem from "../../../../../components/SN/SNForm/SNFormItem";
import SNInput from "../../../../../components/SN/SNForm/SNInput";
import SNSelect from "../../../../../components/SN/SNForm/SNSelect";
import SNTextarea from "../../../../../components/SN/SNForm/SNTextarea";
import SNText from "../../../../../components/SN/SNText";
import SNTitle from "../../../../../components/SN/SNTitle";
import SNSubscriptionsSelect from "../../../../components/EntitiesSelect/SNSubscriptionsSelect";
import { InvoiceApi } from "../../data.t";
import InvoiceItemSectionForm from "./InvoiceItemSectionForm";
import { Card } from "antd/lib";
import Link from "antd/es/typography/Link";

interface PlanTemplateFormProps {
  form: FormInstance;
  editData?: InvoiceApi;
  isLock?: boolean;
}

const InvoiceTemplateForm: FC<PlanTemplateFormProps> = (props) => {
  const { form } = props;
  const currency = form.getFieldValue("subcription") ? form.getFieldValue("subcription").currency : "SEK";

  const [selectedSubscription, setSelectedSubscription] = useState<any>(null);
  const handleSubscriptionSelect = (value: any, option: any) => {
    if (value) {
      form.setFieldsValue({
        subscription: value,
      });
      setSelectedSubscription(option.data); // Store the selected data item

      // Add a new item to the invoice items list
      const currentItems =
        form.getFieldValue("subscription_invoice_items") || [];
      form.setFieldsValue({
        subscription_invoice_items: [
          ...currentItems,
          {
            category: "subscription", // Default category value
            name: option.data.plan.name, // Use the plan name from the selected subscription
            quantity: 1,
            unit: "pcs",
            unit_price: option.data.price,
            vat: 0,
            discount: 0,
            total: option.data.price,
          },
        ],
      });
    }
  };

  const renderGeneralInfo = () => {
    return (
      <section>
        <SNTitle icon={["fal", "info-square"]} title="General information" />

        <SNSubscriptionsSelect
          label="Subscription"
          name="subscription"
          placeholdertext="Select subscription"
          // onChange={(value, option: any) => {
          //   if (value) {
          //     form.setFieldsValue({
          //       subscription: value.value,
          //     });
          //   }
          // }}
          onSelect={handleSubscriptionSelect}
          required
        />
        {selectedSubscription && (
          <Card title="Selected Subscription Details" bordered={true}>
            <p>
              <strong>Company:</strong>{" "}
              {selectedSubscription.company.company_name}
            </p>
            <p>
              <strong>Plan:</strong> {selectedSubscription.plan.name}
            </p>
            <p>
              <strong>Price:</strong> {selectedSubscription.price} SEK
            </p>
          </Card>
        )}
        <SNFormItem
          label="Invoice number"
          style={{ marginTop: 20 }}
          name="number"
          required
          tooltip="Specific ID for the Invoice"
        >
          <SNInput
            maxLength={20}
            placeholder={"example: INVOICE-137, INV999,..."}
          />
        </SNFormItem>
        <SNFormItem label="Invoice date" name="date" required>
          <DatePicker />
        </SNFormItem>

        <SNFormItem label="Invoice terms" name="invoice_terms">
          <SNSelect
            options={[
              { label: "NET 15", value: 15 },
              { label: "NET 30", value: 30 },
            ]}
          />
        </SNFormItem>

        <SNFormItem label="Due date" name="due_date" required>
          <DatePicker />
        </SNFormItem>
      </section>
    );
  };

  const renderInvoiceInfo = () => {
    return (
      <section>
        <SNTitle icon={["fal", "file-invoice"]} title="Invoice information" />

        <SNFormItem label="Invoice Ref" name="invoice_ref">
          <SNInput maxLength={50} />
        </SNFormItem>

        <SNFormItem label="Invoice email" name="invoice_email">
          <SNInput maxLength={50} />
        </SNFormItem>

        <SNTitle icon={["fal", "map-marked-alt"]} title="Invoice address" />
        <SNAddress name="invoice_address" form={form} />
      </section>
    );
  };

  const renderOtherInfo = () => {
    return (
      <section>
        <SNTitle icon={["fal", "info"]} title="Other information" />

        <SNFormItem label="OCR number" name="ocr_number">
          <SNInput maxLength={50} />
        </SNFormItem>

        <SNFormItem name="note" label="Note (Display on invoice)">
          <SNTextarea
            placeholder="Note to the customer for this subscription"
            autoSize={{ minRows: 5, maxRows: 5 }}
          />
        </SNFormItem>
        <SNTitle icon={"firefox"} title="Public URL" />
        <Link target="_blank" href={form.getFieldValue("public_url")}> {form.getFieldValue("public_url")}</Link>
      </section>
    );
  };

  const renderlistItem = () => {
    return (
      <section>
        <SNTitle icon={["fal", "shopping-basket"]} title="Invoice Item" />

        <InvoiceItemSectionForm form={form} name="subscription_invoice_items" />

        <Row gutter={[12, 16]} justify="end" style={{ marginTop: 30 }}>
          <Col xl={6} lg={12} md={12} xs={12}>
          <Descriptions column={1} bordered size="small" contentStyle={{ textAlign: "right"}}>
            <Descriptions.Item label={`Sub total (${currency})`}>
              <SNText text={form.getFieldValue('sub_total')} style={{textAlign: "right"}}/>
            </Descriptions.Item>
            <Descriptions.Item label={`Discount (${currency})`}>
              <SNText text={form.getFieldValue('discount')} style={{textAlign: "right"}}/>
            </Descriptions.Item>
            <Descriptions.Item label={`VAT (${currency})`}>
              <SNText text={form.getFieldValue('vat_amount')} style={{textAlign: "right"}}/>
            </Descriptions.Item>
            <Descriptions.Item label={`Total (${currency})`} labelStyle={{ fontWeight: "bold"}}>
              <SNText text={form.getFieldValue('total')} style={{ fontWeight: "bold" }}/>
            </Descriptions.Item>
          </Descriptions>
          </Col>
        </Row>
      </section>
    );
  };
  return (
    <div>
      <Row gutter={[40, 16]}>
        <Col xl={8} lg={16} md={16} xs={24}>
          {renderGeneralInfo()}
        </Col>
        <Col xl={8} lg={16} md={16} xs={24}>
          {renderInvoiceInfo()}
        </Col>
        <Col xl={8} lg={16} md={16} xs={24}>
          {renderOtherInfo()}
        </Col>
      </Row>
      <Row gutter={[40, 16]} style={{ marginBottom: 30 }}>
        <Col xl={24} lg={24} md={24} xs={24}>
          {renderlistItem()}
        </Col>
      </Row>
    </div>
  );
};

export default InvoiceTemplateForm;
