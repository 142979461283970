import { blue } from "@ant-design/colors";
import ProTable, {
  ActionType,
  ProColumns,
  RequestData,
} from "@ant-design/pro-table";
import { useRef } from "react";
import api, { url } from "../../../../api";
import SNButton from "../../../../components/SN/SNButton";
import SNButtonGroup from "../../../../components/SN/SNButtonGroup";
import { SNMenuItemProps } from "../../../../components/SN/SNMenuItem";
import SNPanel from "../../../../components/SN/SNPanel";
import path from "../../../Root/routePaths";
import { Invoice } from "../../data.t";
// import { getInvoiceStatusTag } from "../const";
import { successAlert } from "lib/alertHelper";
import { Typography } from "antd";
import { paymentStatus } from "screens/Subscriptions/const";

const AllInvoices = ({ history }) => {
  const listRef = useRef<ActionType>();

  const _renderList = () => {
    const columns: ProColumns<Invoice>[] = [
      {
        dataIndex: "id",
        key: "id",
        title: "ID",
        width: 70,
        render: (value) => {
          return <Typography.Link onClick={() => history.push(path.edit_invoice.replace(":invoice_id", value.toString()))}>#{value}</Typography.Link>
        },
      },
      {
        dataIndex: "subscription",
        key: "subscription",
        title: "Subscription",
        valueType: "indexBorder",
        render: (_, record) => {
          // show subscription name and company with link to subscription details

          return (
            <Typography.Link
              onClick={() =>
                history.push(path.subscription_details.replace(":sub_id", record.subscription.id.toString()))
              }
            >
              {record.subscription.company.company_name} - {record.subscription.plan.name}
            </Typography.Link>
          );
        },
      },
      {
        dataIndex: "number",
        key: "number",
        title: "Number",
        sorter: true,
      },
      {
        dataIndex: "date",
        key: "date",
        title: "Invoice date",
        sorter: true,
        valueType: "date",
      },
      {
        dataIndex: "due_date",
        key: "due_date",
        title: "Due date",
        sorter: true,
        valueType: "date",
      },
      {
        dataIndex: "total",
        key: "total",
        title: "Invoice amount",
        render: (value) => `${value ?? 0} (SEK)`,
      },
      {
        dataIndex: "amount_paid",
        key: "amount_paid",
        title: "Amount paid",
        render: (value: any) => `${value ?? 0} (SEK)`,
      },
      // {
      //   dataIndex: "status",
      //   key: "status",
      //   title: "Status",
      //   render: (value: any) => getInvoiceStatusTag(value),
      // },
      {
        dataIndex: "payment_status",
        key: "payment_status",
        title: "Payment status",
        render: (value: any) => paymentStatus(value),
      },
      {
        key: "actions",
        width: 50,
        fixed: "right",
        search: false,
        render: (value: any, data: any) => {
          const actions: SNMenuItemProps[] = [
            {
              key: "info",
              icon: ["fal", "info"],
              title: "Details",
              link: path.invoice_details.replace(":invoice_id", data.id),
            },
            {
              key: "edit",
              icon: ["fal", "pencil-alt"],
              title: "Edit",
              link: path.edit_invoice.replace(":invoice_id", data.id),
            },
            {
              key: "copy",
              icon: ["fal", "copy"],
              title: "Copy link payment",
              onClick: () => {
                navigator.clipboard.writeText(data.public_url);
                successAlert("Link copied to clipboard");
              },
            },
          ];
          return <SNButtonGroup dropdown={actions} />;
        },
      },
    ];

    return (
      <ProTable<Invoice, { search: string }>
        actionRef={listRef}
        request={async (
          { pageSize, current, keyword },
          sort,
          filter
        ): Promise<RequestData<Invoice>> => {
          const sortKey = Object.keys(sort).length ? Object.keys(sort)[0] : "";
          const sortParams = sortKey.length
            ? `&ordering=${sort[sortKey] === "descend" ? "-" : ""}${sortKey}`
            : "";

          const urlRequest = `${
            url.SUBSCRIPTION_INVOICE
          }?limit=${pageSize}&offset=${(current - 1) * pageSize}${sortParams}`;
          const res = await api.get(urlRequest);

          return {
            data: res.data.results,
            success: true,
            total: res.data.count,
          };
        }}
        headerTitle="All invoices"
        search={false}
        toolBarRender={() => [
          <SNButton
            key="plus-button"
            title="Create invoice"
            icon="plus"
            iconcolor={blue.primary}
            link={path.create_invoice}
          />,
          <SNButton
            key="filter-button"
            title="Filters"
            icon={["far", "filter"]}
            iconcolor={blue.primary}
          />,
        ]}
        options={{
          setting: false,
          fullScreen: false,
          density: false,
        }}
        size={"small"}
        pagination={{ pageSize: 100, showQuickJumper: true }}
        rowKey={(key) => key.id}
        columns={columns}
        dateFormatter="string"
      />
    );
  };

  return <SNPanel view="list">{_renderList()}</SNPanel>;
};

export default AllInvoices;
