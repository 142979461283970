import { Tag } from "antd";
import Text from "antd/lib/typography/Text";
import { subStatus, SubStatusType } from "./data.t";

export const getSubStatusTag = (status: SubStatusType) => {
  switch (status) {
    case 1:
      return <Tag color={"blue"}>{subStatus[1]}</Tag>;
    case 2:
      return <Tag color={"success"}>{subStatus[2]}</Tag>;
    case 3:
      return <Tag color={"error"}>{subStatus[3]}</Tag>;
    case 4:
      return <Tag color={"orange"}>{subStatus[4]}</Tag>;
    default:
      return null;
  }
};

export const paymentStatus = (status: string) => {
  let color = "";
  switch (status) {
    case "paid":
      color = "success";
      break;
    case "partially_paid":
      color = "processing";
      break;
    case "unpaid":
      color = "error";
      break;
    default:
      return null;
  }
  return (
    <Tag color={color}>
      <Text style={{ textTransform: "capitalize" }}>{status}</Text>
    </Tag>
  );
};
export const getOnEndTag = (type: number) => {
  switch (type) {
    case 1:
      return <Tag color={"blue"}>{"Renew"}</Tag>;
    case 2:
      return <Tag color={"success"}>{"Cancel"}</Tag>;
    case 3:
      return <Tag color={"error"}>{"End"}</Tag>;
    default:
      return null;
  }
};
