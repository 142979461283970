import { blue } from "@ant-design/colors";
import ProTable, {
  ActionType,
  ProColumns,
  RequestData,
} from "@ant-design/pro-table";
import api, { url } from "api";
import SNButton from "components/SN/SNButton";
import SNButtonGroup from "components/SN/SNButtonGroup";
import { SNMenuItemProps } from "components/SN/SNMenuItem";
import SNPanel from "components/SN/SNPanel";
import { DATE_TIME_FORMAT } from "constants/date";
import { paramsToObject } from "lib/locationSearch";
import dayjs from "dayjs";
import qs from "query-string";
import { useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import path from "screens/Root/routePaths";
import { getNewsFeedStatusTag } from "./const";
import { NewsFeedApi } from "./data.t";

const NewsFeed = () => {
  const history = useHistory();
  const location = useLocation();
  const listRef = useRef<ActionType>();

  const _renderList = () => {
    const columns: ProColumns<NewsFeedApi>[] = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        sorter: true,
      },
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
        sorter: true,
        render: (_, data: NewsFeedApi) => {
          return (
            <Link
              to={{
                pathname: path.new_feed_details.replace(":id", `${data.id}`),
              }}
            >
              {data.title}
            </Link>
          );
        },
      },
      {
        title: "Created by",
        dataIndex: ["news_by", "username"],
        key: "news_by",
      },
      {
        title: "Created at",
        dataIndex: "created_at",
        key: "created_at",
        sorter: true,
        render: (value: any) => dayjs(value).format(DATE_TIME_FORMAT),
      },
      {
        title: "Publish at",
        dataIndex: "publish_at",
        key: "publish_at",
        sorter: true,
        render: (value: any) => dayjs(value).format(DATE_TIME_FORMAT),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (value: any) => getNewsFeedStatusTag(value),
      },
      {
        title: "",
        key: "options",
        render: (value: any) => {
          const actions: SNMenuItemProps[] = [
            {
              key: "info",
              icon: ["fal", "info"],
              title: "Details",
              link: path.new_feed_details.replace(":id", `${value.id}`),
            },
            {
              key: "edit",
              icon: ["fal", "pencil"],
              title: "Edit",
              link: `${path.edit_new_feed.replace(":id", value.id)}`,
            },
          ];
          return <SNButtonGroup dropdown={actions} />;
        },
      },
    ];

    return (
      <ProTable<NewsFeedApi, {}>
        actionRef={listRef}
        request={async (
          { pageSize, current },
          sort
        ): Promise<RequestData<NewsFeedApi>> => {
          const sortKey = Object.keys(sort).length ? Object.keys(sort)[0] : "";
          const sortParams = sortKey.length
            ? `&ordering=${sort[sortKey] === "descend" ? "-" : ""}${sortKey}`
            : "";
          const locationParams = qs.stringify(paramsToObject(location.search));

          const urlRequest = `${url.NEWS_FEED}?limit=${pageSize}&offset=${
            (current - 1) * pageSize
          }${sortParams}${locationParams ? `&${locationParams}` : ""}`;

          const res = await api.get(urlRequest);

          return {
            data: res.data.results,
            success: true,
            total: res.data.count,
          };
        }}
        headerTitle="News Feed"
        search={false}
        params={location.search}
        toolBarRender={() => [
          <SNButton
            key="plus-button"
            title="Create new"
            iconcolor={blue.primary}
            icon="plus"
            onClick={() => history.push(path.create_new_feed)}
          />,
          // <NewsFeedFilter key="filter" />]}
        ]}
        options={{
          setting: false,
          fullScreen: false,
          density: false,
        }}
        size={"small"}
        pagination={{ pageSize: 100, showQuickJumper: true }}
        rowKey={(key) => key.id}
        columns={columns}
        dateFormatter="string"
      />
    );
  };

  return <SNPanel view="list">{_renderList()}</SNPanel>;
};

export default NewsFeed;
